
import $app from '@/plugins/modules'
import { Component, Vue } from 'vue-property-decorator'
import { IWorkingYear } from '../types'

@Component
export default class WorkingDaysView extends Vue {
  years: Array<IWorkingYear> = [];
  adding = false;

  async load() {
    try {
      console.log(this.$vuetify.lang);
      const list: Array<IWorkingYear> = await $app.get('/api/clinics/workingdays');
      this.years = list.sort((a, b) => b.year - a.year);
    } catch (err) {
      $app.pushError(err);
      throw err;
    }
  }

  mounted() {
    this.load();
  }
}
